import React from 'react';


const proyectos = () => {

  return (
    <div className='proyectos'>
 
    </div>
  );
};

export default proyectos;
